@import "../../common/variables.scss";

.header {
  box-sizing: border-box;
  width: 100vw;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  border-bottom: 1px solid rgba($white, 0.05);
  position: fixed;
  z-index: 80;
  background-color: #030701;

  @media (max-width: 786px) {
    padding: 12px 20px;
  }

  .logo {
    width: 112px;
    cursor: pointer;
  }

  .header-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;

    @media (max-width: 786px) {
      gap: 16px;
    }

    .community-container {
      display: flex;
      position: relative;
    }

    .menu {
      height: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;

      @media (max-width: 786px) {
        font-size: 12px;
      }

      &:hover {
        color: rgba($white, 0.6);
      }
    }
    .community-box {
      position: absolute;
      top: 36px;
      left: 0;
      width: 160px;
      background-color: #030701;
      border: 1px solid $primary;
      display: flex;
      flex-direction: column;
      border-radius: 10px;

      .community {
        width: 100%;
        box-sizing: border-box;
        padding: 12px 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;

        &:hover {
          opacity: 60%;
          cursor: pointer;
        }

        .label {
          font-size: 14px;
        }

        .sns {
          width: 20px;
          height: 20px;
        }
      }
    }

    .margin-block {
      width: 15px;
    }

    .header-button {
      padding: 10px 20px;
      border-radius: 10px;
      border: 1px solid $primary;
      background: rgba($primary, 0.1);
      cursor: pointer;
      font-size: 12px;
      color: $primary;
      white-space: nowrap;

      @media (max-width: 786px) {
        padding: 8px 12px;
        font-size: 12px;
      }

      &:hover {
        background-color: rgba($primary, 0.2);
      }
    }
  }
}
