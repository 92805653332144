@import "../common/variables.scss";

.landing-page {
  width: 100vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .main-page {
    box-sizing: border-box;
    padding-top: 60px;
    width: 100vw;
    height: 88vh;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    @media (max-width: 700px) {
      height: 60vh;
    }

    .logo-lg-left {
      z-index: 0;
      position: absolute;
      top: calc(50% - 24vh);
      transform: translateY(-50%);
      left: 0;
      width: 26vw;

      @media (max-width: 900px) {
        top: calc(50% - 16vh);
      }

      @media (max-width: 700px) {
        top: 50%;
      }
    }

    .logo-lg-right {
      z-index: 0;
      position: absolute;
      top: calc(50% - 28vh);
      transform: translateY(-50%);
      right: 0;
      width: 32vw;
      @media (max-width: 900px) {
        top: calc(50% - 20vh);
      }
    }

    .catch-phrase {
      z-index: 20;
      text-align: center;
      font-family: Montserrat, sans-serif;
      font-size: 60px;
      font-weight: 800;
      line-height: 120%;
      white-space: nowrap;
      text-transform: uppercase;

      @media (max-width: 1600px) {
        font-size: 48px;
      }

      @media (max-width: 900px) {
        font-size: 40px;
      }

      @media (max-width: 600px) {
        font-size: 20px;
      }
    }

    .description {
      z-index: 20;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.8;
      color: rgba(white, 0.6);

      @media (max-width: 600px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
      }
    }

    .main-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      padding: 10px 20px;
      background: linear-gradient(97deg, #377bff 8.92%, #20458b 100%);
      border-radius: 24px;
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      @media (max-width: 500px) {
        font-size: 12px;
      }
    }
  }

  .beta-mining-page {
    box-sizing: border-box;
    padding: 40px 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .description {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.8;
      z-index: 30;

      @media (max-width: 600px) {
        font-weight: 400;
        line-height: 1.4;
      }
    }

    .primary {
      color: $primary;
    }

    .beta-mining-v2 {
      box-sizing: border-box;
      padding: 40px;
      width: 100%;
      max-width: 600px;
      margin: 40px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .beta-mining-v2-img {
        width: 30%;

        @media (max-width: 600px) {
          width: 30%;
        }
      }

      @media (max-width: 600px) {
        margin: 0;
        padding: 24px;
      }
    }

    .beta-mining-img {
      width: 80%;
      z-index: 0;
      margin-top: -60px;

      @media (max-width: 600px) {
        margin-top: -24px;
      }
    }
  }

  .backers-page {
    width: 100vw;
    min-height: 36vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 48px;
    position: relative;
    margin: -0.2vw 0;

    .backers-img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }

    .backers-description {
      font-size: 16px;

      @media (max-width: 600px) {
        font-size: 14px;
      }
    }
  }

  .audit-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 100px 0;

    .audit-img {
      cursor: pointer;
      max-width: 600px;
      width: 50%;
    }
  }

  .features-page {
    box-sizing: border-box;
    width: 100%;
    padding: 0 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 60px;

    @media (max-width: 900px) {
      padding: 0 24px;
    }

    .features-box-top {
      width: 100%;
      max-width: 800px;
      height: 400px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;

      @media (max-width: 900px) {
        height: 320px;
      }

      @media (max-width: 786px) {
        flex-direction: column;
        height: 500px;
      }

      .feature-box-lg {
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-size: cover;
        box-sizing: border-box;
        padding: 40px;
        border-radius: 12px;

        @media (max-width: 786px) {
          width: 100%;
        }
      }

      .first {
        background-image: url("../assets/pngs/img-bg-hta.png");
      }

      .second {
        background-image: url("../assets/pngs/img-bg-acmm.png");
      }
    }

    .features-box-bottom {
      width: 100%;
      height: 400px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      max-width: 1200px;
      gap: 20px;

      @media (max-width: 1024px) {
        flex-direction: column;
        height: 720px;
        gap: 20px;
      }

      .feature-box-sm {
        box-sizing: border-box;
        padding: 20px;
        width: 33%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        background-image: url("../assets/pngs/img-feature-bg.png");
        background-size: cover;
        position: relative;
        overflow: hidden;
        border-radius: 12px;

        @media (max-width: 1024px) {
          width: 100%;
          flex-direction: row;
          align-items: center;
          padding: 0 40px;

          .feature-box-title {
            max-width: 60%;
          }
        }
      }
    }
  }

  .join-page {
    width: 100vw;
    margin: 160px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .description {
      font-size: 16px;
      line-height: 1.8;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 1.4;
      }

      .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 10px 20px;
        background: linear-gradient(97deg, #377bff 8.92%, #20458b 100%);
        border-radius: 24px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }

        @media (max-width: 500px) {
          font-size: 12px;
        }
      }
    }
  }

  .community-page {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    margin-bottom: 60px;

    .community-box {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .sns-container {
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: 720px) {
          padding: 6px;
        }

        &:hover {
          opacity: 0.6;
          cursor: pointer;
        }

        .sns {
          width: 40px;
          height: 40px;

          @media (max-width: 720px) {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  .landing-title {
    color: #fff;
    text-align: center;
    text-shadow: 0 0 10px #f1f7ff;
    -webkit-text-stroke-width: 1;
    -webkit-text-stroke-color: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
    z-index: 30;

    @media (max-width: 900px) {
      font-size: 36px;
    }
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }

  .backers {
    position: absolute;
    top: 60px;
  }

  .title-sub {
    text-align: start;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    color: $background;
    white-space: nowrap;
    text-shadow: -1px -1px 0 $primary, 1px -1px 0 $primary, -1px 1px 0 $primary,
      1px 1px 0 $primary;

    @media (max-width: 1320px) {
      font-size: 24px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
      text-shadow: -1px -1px 0 $primary, 1px -1px 0 $primary,
        -1px 1px 0 $primary, 1px 1px 0 $primary;
    }
  }

  .title-main {
    width: 100%;
    text-align: start;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    text-transform: uppercase;
    white-space: nowrap;
    color: $white;

    @media (max-width: 1320px) {
      font-size: 24px;
    }

    @media (max-width: 500px) {
      font-size: 18px;
    }
  }

  .feature-description {
    width: 100%;
    word-break: break-word;
    font-size: 14px;
    text-align: start;
    color: rgba(white, 0.6);
    margin-top: 12px;
    text-shadow: 0 0 6px black;
    line-height: 1.4;
    z-index: 50;

    @media (max-width: 900px) {
      font-size: 12px;
    }

    @media (max-width: 500px) {
      text-shadow: 2px 2px 4px black;
    }
  }

  .feature-description.lg {
    color: white;
  }

  .feature-img {
    height: 50%;
    z-index: 0;
    opacity: 0.8;
    position: absolute;
    bottom: 20px;
    right: 20px;

    @media (max-width: 1160px) {
      height: 40%;
    }

    @media (max-width: 1024px) {
      height: 80%;
    }

    @media (max-width: 700px) {
      height: 60%;
    }

    @media (max-width: 500px) {
      height: 50%;
    }
  }
}
